import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import quotes from '../../images/whitequotes.svg';

const WhyEdduus = props => (
  <Layout>
    <SEO title="Why Blockchain" />
    <section className="whyedduus" id="whyedduus">
      <div className="whyedduus-content">
          <p className="whyedduus-content-title">WHY EDDUUS</p>
          <div className="whyedduus-content-textbox">
            <div className="whyedduus-content-textbox-header">
              <img alt="Quotes" src={quotes} />
              <p className="whyedduus-content-textbox-header-text">Edduus revolutionizes the way people learn and socialize</p>
            </div>
            <div className="whyedduus-content-textbox-body">
              <p className="whyedduus-content-textbox-body-text">Built on the core principles of micro-learning, credible learning and shared learning, Edduus creates a new dimension on how people gain knowledge.</p>
              <p className="whyedduus-content-textbox-body-text">
                The benefits that underpin Edduus are simple:
                <ul><li>Learn only what you need to learn when you need to apply it</li>
                <li>Access micro content that match real situations</li>
                <li>Receive real-time feedback from peers to refine understanding and ensure competence</li>
                <li>Share and learn seamlessly with members in with like needs globally using a full suite of unique social tools</li></ul>
              </p>
              <p className="whyedduus-content-textbox-body-text">Edduus offers a first-of-its-kind incentive system to benefit members whenever they enage with, create and share content.</p>
            </div>
          </div>
      </div>
    </section>
  </Layout>
);

export default WhyEdduus;
